
body {
    button {
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &.btn, &.btn-primary, &.btn-secondary, &.btn-light {
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    textarea {
        resize: none;
    }
    label {
        &.btn, &.btn-primary, &.btn-secondary, &.btn-light {
            &.focus {
                box-shadow: none;
            }
        }
    }
    input {
        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }
    .form-control, .custom-select {
        background: $gray;
        border: none;
        color: #fff;
        &:focus, &[readonly] {
            box-shadow: none;
            background: $gray;
            color: #fff;
        };
        &.is-invalid {
            border: 1px solid $light_red;
            background-color: $gray;
        }
    }
    .custom-typehead {
        .dropdown-menu {
            width: calc(100% - 10px);
            background-color: darken($color: $gray, $amount: 5) !important;
            .dropdown-item {
                color: #fff;
            }
            .dropdown-item.active, .dropdown-item:active {
                background-color: $gray;
                color: $form_elements_color;
            }
        }
    }
    .input-group-text {
        background-color: $form_elements_color;
        color: #fff;
        border-color: $form_elements_color;
        cursor: pointer;
        &.disabled {
            background-color: $form_elements_disabled_color;
            border-color: $form_elements_disabled_color;
            pointer-events: none;
            cursor: default;
        }
    }
    .btn {
        color: #fff;
        &:hover {
            color: inherit;
        }
    }
    /* .btn-primary {
        background-color: $form_elements_color;
        border-color: $form_elements_color;
        &:hover {
            background-color: $form_elements_color;
            border-color: $form_elements_color;
        }
    } */
    .custom-select {
        background: url('../../assets/svgs/chevron-down.svg') no-repeat center right 7.5px;
        background-size: 12.5px 12.5px;
        background-color: $gray;
    }
    /* CHECKBOXES */
    .custom-checkbox {
        .custom-control-label:before,
        .custom-control-input:disabled ~ .custom-control-label::before {
            background: $gray;
            //border-color: $form_elements_color;
            border: none;
            border-radius: 2px;
        }
        .custom-control-input:focus ~ .custom-control-label:before {
            border-color: $form_elements_color;
            border: none;
            box-shadow: none;
        }
        .custom-control-input:checked ~ .custom-control-label:before {
            border-color: $form_elements_color;
            background-color: $form_elements_color;
        }
        .custom-control-input:disabled:checked ~ .custom-control-label::before {
            background-color: $form_elements_disabled_color;
        }
        .custom-control-input:disabled {
            background-color: $form_elements_disabled_color;
        }
    }
    /* RADIOS */
    .custom-radio {
        .custom-control-input:checked ~ .custom-control-label::before {
            background-color: $form_elements_color;
            border-color: $form_elements_color;
        }
    } 

    /* SWITCHES */
    /* .custom-switch {
        .custom-control-input {
            cursor: pointer;
            z-index: 1;
        }
        .custom-control-input:focus ~ .custom-control-label:before {
            box-shadow: none;
        }

        .custom-control-label:before {
            //background-color: $gray_400;
            border: none;
            border-radius: 1rem;
        }
        .custom-control-label:after {
            background-color: #fff;

            border-radius: 1rem;
        }
        .custom-control-input:checked ~ .custom-control-label {
            color: $form_elements_color;
        }
        .custom-control-input:checked ~ .custom-control-label:before {
            border-color: transparent;
            background-color: rgba($form_elements_color, 0.3);
        }
        .custom-control-input:checked ~ .custom-control-label:after {
            background-color: $form_elements_color;
        }
    } */
}
