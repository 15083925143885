
@import '../typography',
        'form';
        
.custom-datepicker{
    .dropdown-menu {
        position: absolute;
        background-color: $deep_black;

        .custom-select {
            background-color: $gray;
        }
        .ngb-dp-header, .ngb-dp-week {
            background-color: $deep_black;
        }
        .ngb-dp-weekday {
            color: $light_red;
        }
        .ngb-dp-month {
            background-color: $deep_black;
            color: #fff;
        }
        .btn-light {
            color: #fff;
            &.bg-primary, &:hover {
                background-color: $light_red !important;
                border-color: $light_red !important;
                outline: none;
            }
        }
        .ngb-dp-day[tabindex="0"] {
            outline: none;
        }
    }
    .fa-calendar {
        position: absolute;
        top: 8px;
        right: 10px;
        color: white;
        z-index: 3
    }
}
