
@import '~bootstrap/scss/bootstrap',
        'scss_modules/base',
        'scss_modules/customisations/customisations';

body {
    background: $deep_black;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    a {
        color: $link_color;
        &:hover {
            color: $link_color;
        }
    }
    img { max-width: 100%; }
    button {
        &.brand_default {
            @include DefaultButton();
        }
        &.brand_transparent {
            @include TrasparentButton();
        }
    }
    label {
        &.brand_default {
            @include DefaultLabel();
        }
    }
    .panel_wrapper {
        background: $deep_gray;
        margin-bottom: 1rem;
        padding: 1.5rem;
        border-radius: .25rem;
        &.cascade_wrap {
            margin-bottom: .25rem;
            border-radius: 0px;
            &:first-of-type {
                border-top-right-radius: .25rem;
                border-top-left-radius: .25rem;
            }
            &:last-of-type {
                margin-bottom: 1rem;
                border-bottom-right-radius: .25rem;
                border-bottom-left-radius: .25rem;
            }
        }
    }
    .container_wrapper {
        padding-left: 2.1875rem;
        padding-right: 2.1875rem;
        margin-bottom: 3rem;
        
        .header_wrapper {
            min-height: 98px;
            background-color: $gray;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
            &.sticky {
                position: sticky;
                top: 0;
                z-index: 4;
            }
            .row {
                min-height: 98px;
            }
            &+.panel_wrapper {
                border-bottom-right-radius: .25rem;
                border-bottom-left-radius: .25rem;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
            &+.panel_wrapper.cascade_wrap {
                border-radius: 0;
            }
        }
        .breadcrumb_wrapper {
            .actual {
                color: $light_red
            }
            span:not(.actual) {
                cursor: pointer;
            }
        }
    }
    .form_container {
        position: relative;
    }
}
