
/* COLOR BRAND: */
// $thin_blue:                     #e8ecff;
// $light_blue:                    #647dfc;
// $blue:                          #1e2539;
// $dark_blue:                     #151c31;

$deep_black:            #121212;
$deep_gray:             #1E1E1E;
$dark_gray:            #222222;
$gray:                  #373737;
$light_red:             #D50C3D;
$dark_red:              #7F122D;

/* 10% DARKEN: */
// $thin_blue_darken:              #cfd3e6;
// $light_blue_darken:             #4b64e3;
// $blue_darken:                   #050c20;
// $dark_blue_darken:              #000318;

$palette: (
    //"thin_color": $thin_blue,
    //"light_color": $light_blue,
    "default_color": $light_red,
    "dark_color": $dark_red,

    //"thin_color_darken": $thin_blue_darken,
    //"light_color_darken": $light_blue_darken
);

$nav_color:                     map-get($palette, "default_color");
//$nav_alternative_color:         map-get($palette, "default_color");

$link_color:                    map-get($palette, "default_color");
$form_elements_color:           map-get($palette, "default_color");
$form_elements_disabled_color:  map-get($palette, "dark_color");

@mixin DefaultButton() {
    background-color: map-get($palette, "default_color");
    color: #fff;
    &:hover {
        background-color: darken(map-get($palette, "default_color"), 10%);
        color: #fff;
    }
}

@mixin TrasparentButton() {
    background-color: transparent;
    color: map-get($palette, "default_color");
    border-color: map-get($palette, "default_color");
    &:hover {
        background-color: map-get($palette, "default_color");
        color: #fff;
    }
}

/* @mixin LightButton() {
    background-color: map-get($palette, "thin_color");
    color: map-get($palette, "light_color");
    &:hover {
        color: map-get($palette, "light_color");
    }
} */

@mixin DefaultLabel() {
    background-color: transparent;
    color: #fff;
    &:hover {
        color: map-get($palette, "default_color");
        border-color: map-get($palette, "default_color");
    }
    &.active {
        background-color: map-get($palette, "default_color");
        color: #fff;
    }
}
